<div class="container">
  <div class="d-flex align-items-center background-box my-3">
    @if (showLogo) {
      <div>
        <img
          class="pointer"
          [routerLink]="'/'"
          src="../assets/images/mc-logo-60.png"
          alt="m-logo"
          [ngStyle]="{ 'width.px': 40 }"
        />
      </div>
      <span class="ms-3 pointer text">|</span>
    }
    <span class="ms-3 pointer text" [routerLink]="'/blog'">BLOG</span>
    <span class="text ms-3 pointer" [routerLink]="'/dev'">DEV</span>
  </div>
  <router-outlet> </router-outlet>
</div>
